import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoSidebar from "../image-components/logo-sidebar"
import ContactTeaser from "../components/contact-teaser"

const ResumePage = ({
  data: {
    images: { edges },
  },
}) => {
  return <Layout>
    <SEO title="Resumé" description={'Portfolio website and developer blog by Erik Altman'} keywords={'Erik Altman, blog, resume, portfolio, contact, web application developer, javascript, martinez, software, node, docker'}/>
    <ContactTeaser />
    <div className="bg-light">
      <div className="py-4 px-4 bg-primary text-light shadow mb-4">
        <div className="my-4 container">
          <div className="row">
            <div className="col-md-4 my-4 d-flex flex-column align-items-center justify-content-center">
              <div style={{ height: `128px`, width: `128px` }}>
                <LogoSidebar style={{ height: `128px`, width: `128px` }} />
              </div>
              <h2 className="mt-1">Erik Altman</h2>
              <span>Martinez, CA</span>
              {/* <span>https://fourstar04.github.io/#resume</span>
            <span>https://www.linkedin.com/in/erik-altman-91b36660/</span> */}
            </div>
            <div className="col-md-8 d-flex flex-column align-items-center justify-content-center lead">
              <ul>
                <li>
                  Front End Engineer @ Rocket Lawyer &#128640;
              </li>
              <li>
                  Previously cofounder and Lead Software Engineer @ AppCatalyst
              </li>
                <li>
                  Product Developer, UX/UI Designer and Full Stack Software Engineer
              </li>
                <li>
                  10 years of experience designing and developing web and native mobile applications for 50+ clients in the healthcare industry
              </li>
                <li>
                  4 years of experience in team building & startup development
              </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4">
        <blockquote className="blockquote bd-callout bd-callout-primary bg-white">Most people think of software development as writing code and designing information architecture but my skills extend beyond that.  I work with internal and external stakeholders to design and develop flexible products that create value for customers and end users.  I lead my team in developing robust products and services so that our customers realize the maximum benefit in working with us.</blockquote>
      </div>
      <div className="">
        <div className="container px-4">
          <div className="row flex-row flex-row-reverse">
            <div className="col-md-8">
              <div className="mb-3 card">
                <div className="card-header">Experience</div>
                <div className="card-body">
                  <h3>Rocket Lawyer</h3>
                  <span className="text-muted">June 2020 - Present</span>
                  <ul className="timeline">
                    <li>
                      <h4>Front End Engineer</h4>
                      <span className="text-muted">June 2020 - Present</span>
                      <p>Democratizing access to legal services by supporting and enhancing client applications for markets around the globe.</p>
                    </li>
                  </ul>
                  <h3>AppCatalyst</h3>
                  <span className="text-muted">January 2016 - June 2020 (4 years)</span>
                  <ul className="timeline">
                    <li>
                      <h4>Cofounder</h4>
                      <span className="text-muted">January 2016 - June 2020 (4 years)</span>
                      <p>Form company to provide technology services to Self Care Decisions and develop other web and mobile application products for the healthcare market.</p>
                    </li>
                    <li>
                      <h4>Full Stack Engineer</h4>
                      <span className="text-muted">January 2016 – June 2020 (4 years)</span>
                      <p>Design, develop, deliver, document and support REST API and CMS services for microservice infrastructure. Develop and maintain javascript libraries, boilerplate and reference implementations to streamline the development of additional services.</p>
                    </li>
                    <li>
                      <h4>User Experience Designer</h4>
                      <span className="text-muted">March 2016 – June 2020 (3 years 3 months)</span>
                      <p>Design user experiences for mobile applications and content management systems. Work with groups of internal and external stakeholders to discover, establish and satisfy product requirements. Design and conduct user tests to validate designs.</p>
                    </li>
                    <li>
                      <h4>Lead iOS Developer</h4>
                      <span className="text-muted">January 2016 – January 2018 (2 years 1 month)</span>
                      <p>Develop iOS mobile application framework to streamline mobile application development and support services. Develop ClinDOC Pro and Surgery Connect iOS applications. Document, maintain and extend framework. Train junior developers to leverage framework to develop and support other applications.</p>
                    </li>
                  </ul>
                  <h3 className="mt-4">Self Care Decisions</h3>
                  <span className="text-muted">January 2009 – January 2016 (7 years)</span>
                  <ul className="timeline">
                    <li>
                      <h4>Full Stack Engineer</h4>
                      <span className="text-muted">October 2012 – January 2016 (3 years 4 months)</span>
                      <p>Design, develop and support Symptom Checker API Developer Portal and embeddable symptom checker web application.</p>
                    </li>
                    <li>
                      <h4>Technical Support Lead</h4>
                      <span className="text-muted">October 2012 – January 2016 (3 years 4 months)</span>
                      <p>Provide sales support services, demonstrating Symptom Checker API to prospective customers. Onboard Symptom Checker API customers and provide ongoing technical support services. Develop and maintain API documentation and supplemental resources.</p>
                    </li>
                    <li>
                      <h4>iOS Development Contractor</h4>
                      <span className="text-muted">January 2009 – January 2016 (7 years)</span>
                      <p>Design, develop and support white-labelled Symptom Checker mobile applications sold by Self Care Decisions as an application as a service to healthcare systems and insurance providers.</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-3 card">
                <div className="card-header">Education</div>
                <div className="card-body">
                  <ul className="timeline">
                    <li>
                      <h4>Nielsen-Norman Group</h4>
                      <span className="text-muted">UX Certification - Mar 2, 2017</span>
                      <p>Coursework focusing on qualitive and quantitative UX research, domain modeling and navigation design, mobile application prototyping and iterative design.</p>
                    </li>
                    <li>
                      <h4>Mongo University</h4>
                      <div className="text-muted">2014 to present</div>
                      <small>New Features and Tools in MongoDB 4.0 | Basic Cluster Administration | The MongoDB Aggregation Framework | MongoDB Performance | MongoDB for Node.js Developers</small>
                      <p>Coursework focusing on application development with MongoDB, MongoDB administration and analysis.</p>
                    </li>
                    <li>
                      <h4>Arizona State University</h4>
                      <div className="text-muted">January 2011</div>
                      <small>Bachelor of Arts (B.A.) Field Of Study Philosophy Grade 3.6</small>
                      <p>Student of analytic philosophy, computational logic, philosophy of the mind and futurist philosophy. Student of german language.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3">
                <div className="card-header">Interests</div>
                <ul className="list-group">
                  <li className="list-group-item bg-white text-dark">Web and mobile application design</li>
                  <li className="list-group-item bg-white text-dark">Web application development</li>
                  <li className="list-group-item bg-white text-dark">iOS development</li>
                  <li className="list-group-item bg-white text-dark">API design and development</li>
                  <li className="list-group-item bg-white text-dark">Communication and documentation</li>
                  <li className="list-group-item bg-white text-dark">Requirements Gathering</li>
                  <li className="list-group-item bg-white text-dark">Domain modeling</li>
                  <li className="list-group-item bg-white text-dark">Application prototyping</li>
                  <li className="list-group-item bg-white text-dark">UX design and research</li>
                  <li className="list-group-item bg-white text-dark">Information architecture</li>
                  <li className="list-group-item bg-white text-dark">Document databases</li>
                  <li className="list-group-item bg-white text-dark">Data analysis</li>
                  <li className="list-group-item bg-white text-dark">Development Operations</li>
                </ul>
              </div>
              <div className="card my-3">
                <div className="card-header">Skills</div>
                <ul className="list-group">
                  {
                    skills.map((skill, i) => {
                      let image = edges.find(edge => skill.icon === edge.node.relativePath)
                      return image ? <li key={i} className="list-group-item bg-white text-dark d-flex align-items-center">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {skill.tooltip}
                            </Tooltip>
                          }
                        >
                          <div className="d-flex flex-fill">
                            <div style={{ width: 20, height: 20 }} className="mr-2">
                              <Img style={{ maxWidth: 24, maxHeight: 24 }} fluid={image.node.childImageSharp.fluid} alt={skill.name + ` logo`} />
                            </div>
                            {skill.name}
                          </div>
                        </OverlayTrigger>
                      </li> : null
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
}

const skills = [
  {
    name: "Javascript",
    icon: "js.png",
    tooltip: "10+ years building web sites and apps"
  },
  {
    name: "Typescript",
    icon: "ts.png",
    tooltip: "3+ years developing content management systems with angular"
  },
  {
    name: "HTML",
    icon: "html5.png",
    tooltip: "10+ years building web sites and apps"
  },
  {
    name: "CSS",
    icon: "css3.png",
    tooltip: "10+ years styling web sites and apps"
  },
  {
    name: "SCSS",
    icon: "sass.png",
    tooltip: "5 years styling sites and apps with bootstrap and scss"
  },
  {
    name: "Angular",
    icon: "angular.png",
    tooltip: "7 years building single page apps with angular"
  },
  {
    name: "RxJS",
    icon: "rxjs.png",
    tooltip: "2+ years building reactive web apps"
  },
  {
    name: "Node.js",
    icon: "nodejs.png",
    tooltip: "7 years supporting node web services in production"
  },
  {
    name: "Express.js",
    icon: "nodejs.png",
    tooltip: "7 years implementing REST APIs with Express.js"
  },
  {
    name: "MongoDB",
    icon: "mongodb.jpg",
    tooltip: "7 years persisting data in mongodb for a multitide of content management systems and microservices"
  },
  {
    name: "Git",
    icon: "git.png",
    tooltip: "10 years collaboratively developing with git"
  },
  {
    name: "NGINX / Microservices",
    icon: "nginx.png",
    tooltip: "7 years delivering content and applications with NGINX (and apache2) with SSL/TLS"
  },
  {
    name: "Docker / Containerization",
    icon: "docker.png",
    tooltip: "5 years scripting and shipping containers to production"
  },
  {
    name: "Linux / Ubuntu",
    icon: "linux.png",
    tooltip: "5 years administrating docker and LAMP linux virtual private servers with linode"
  },
  {
    name: "iOS",
    icon: "apple.png",
    tooltip: "9 years developing iOS apps for 50+ customers"
  },
  {
    name: "UIKit",
    icon: "apple.png",
    tooltip: "9 years developing iOS apps for 50+ customers"
  },
  {
    name: "Swift",
    icon: "swift.png",
    tooltip: "2 years developing iOS libraries and apps with Swift"
  },
  {
    name: "Objective-C",
    icon: "apple.png",
    tooltip: "7 years developing iOS apps with Objective-C"
  },
  {
    name: "JIRA / Agile / Kanban",
    icon: "jira.png",
    tooltip: "5 years studying and applying Agile/Lean methods with the help of JIRA software"
  },
  {
    name: "Confluence / Wiki",
    icon: "confluence.png",
    tooltip: "3 years documenting projects in Confluence"
  },
  {
    name: "Google Analytics",
    icon: "google-analytics.png",
    tooltip: "10+ years analyzing website traffic and designing analytics implementations for web and mobile apps"
  },
  {
    name: "Google Tag Manager",
    icon: "tag-manager.png",
    tooltip: "3 years administrating containers for cross-platform mobile apps"
  },
  {
    name: "Google Material Design",
    icon: "material-design.png",
    tooltip: "3 years designing web and mobile UI Kits that are heavily influenced by the Material Design system"
  },
  {
    name: "Firebase",
    icon: "firebase.png",
    tooltip: "4 years using firebase for cloud messaging and analytics for cross-platform mobile apps"
  },
  {
    name: "Adobe Photoshop",
    icon: "photoshop.png",
    tooltip: "15+ years rendering graphics with photoshop"
  },
  {
    name: "Adobe Illustrator",
    icon: "illustrator.png",
    tooltip: "15+ years designing vector artwork with illustrator"
  },
  {
    name: "Sketch App",
    icon: "sketch.png",
    tooltip: "4 years designing user interfaces, UI kits and high fidelity mock ups for multiple platforms in sketch"
  },
  {
    name: "Balsamiq",
    icon: "balsamiq.png",
    tooltip: "5 years designing user experiences and low fidelity user interfaces"
  },
  {
    name: "SMART on FHIR",
    icon: "smart.png",
    tooltip: "2 years integrating health apps with SMART on FHIR EHR environments"
  },
]

export default ResumePage

export const pageQuery = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 40) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`